<script setup lang="ts">
import Myappointments from './Myappointments.vue'
import { ref, watch,nextTick, onMounted, reactive } from 'vue';
import {createBookingApp} from '../main.ts'
import Popup from './Popup.vue';
import intlTelInput from 'intl-tel-input';
import repository from '../api/repository.ts';
import { ApiResponse } from '../types/common-types.ts';
import { useSettingStore } from '../stores/settingsstore.ts';

const activeTab = ref('myappointments');
let bookingAppInstance:any = null;
const bookAppointment = ref(false)
const phoneNumberInput = ref()
const userInformation = ref<any>({})
const errorPhoneMsg = ref('')
const errorPasswordMsg = ref('')
const settingsStore = useSettingStore()
let phonesettings: any = {}
let selectedcountries:string[] = [];


const changepassword = reactive({
    password: '',
    cnfpassword:''
})


onMounted(() => {
    phonesettings = settingsStore.getSettings('phonesettings')

    if (!phonesettings?.showallcountries) {
        selectedcountries = phonesettings?.selectedcountries ?? []
    }

})

let iti:any = null;


const getUserInformation = async () => {
    
    try {
        const res:ApiResponse<any> = await repository.getuserData()
        if (res.data.status === 200) {
            userInformation.value = res.data.data
            console.log(userInformation.value)
        }
    } catch (e) {
        console.log(e)
    }

    iti = intlTelInput(phoneNumberInput.value, {
        initialCountry:userInformation.value.prefix ?? phonesettings.defaultcountry,
        separateDialCode: true,
        strictMode: true,
        onlyCountries: selectedcountries,
        formatAsYouType:true,
        validationNumberType:'MOBILE',
        utilsScript: '/node_modules/intl-tel-input/build/js/utils.js'
    });
    // console.log(iti.isValidNumberPrecise())
}

watch(() => activeTab.value, async(first) => {
    const instance = () => createBookingApp();
    const createInstance = instance();
    if (first === 'newappointment') {
        await nextTick();
        const el = document.getElementById('boss_booking_root_inline');
        if (el) {
            bookingAppInstance = createInstance;
            bookingAppInstance.mount('#boss_booking_root_inline');
            
        }
    } else {
        if (bookingAppInstance) {
            bookingAppInstance.unmount()
            bookingAppInstance = null    
        }
    }

    if (first === 'myinformation') {
        getUserInformation()
    }
})

watch(() => bookAppointment.value, async (first) => {
    const instance = () => createBookingApp();
    const createInstance = instance();
    if (first) {
        await nextTick();
        const el = document.getElementById('boss_booking_root_popup');
        if (el) {
            bookingAppInstance = createInstance;
            bookingAppInstance.mount('#boss_booking_root_popup');
            
        }
    } else {
        if (bookingAppInstance) {
            bookingAppInstance.unmount()
            bookingAppInstance = null    
        }
    }   
})

const handleSaveData = async (e:any) => {
    e.preventDefault();
    if (iti.isValidNumberPrecise()) {
        userInformation.value.prefix =  iti.getSelectedCountryData().iso2 ?? ''
        try {
            const res:ApiResponse<any> = await repository.submitUserData(userInformation.value)
            if (res.data.status === 200) {
                console.log(res.data.message)
                errorPhoneMsg.value = ''
            }
        } catch (e) {
            console.log(e)
        }    
    } else {
        errorPhoneMsg.value = 'Please select country & fill phno'
    }
    
    
}

const handleChangePassword = async (e: any) => {
    e.preventDefault()
    if (changepassword.cnfpassword !== changepassword.password) {
        errorPasswordMsg.value = 'Password isn\'t matched'     
        return
    }

    errorPasswordMsg.value = ''

    try {
        const res: ApiResponse<any> = await repository.changePassword({password:changepassword.password})
        if (res.data.status === 200) {
            console.log(res.data.message)
            changepassword.cnfpassword = ''
            changepassword.password = ''
        }
    } catch (e) {
        console.log(e)
    }

}

</script>

<template>
    <Popup title="" v-model:is-open="bookAppointment" >
        <template #body>
            <div id="boss_booking_root_popup">

            </div>
        </template>
    </Popup>
    <div id="boss_public_dashboard" class="w-full flex flex-col">
        <div class="flex justify-between">
            <div class="w-max bg-slate-200 rounded-lg p-1 text-slate-600 font-semibold flex justify-center text-sm">
                <button @click="activeTab = 'myappointments'" type="button" class="h-8 px-2 rounded-lg flex justify-center items-center" :class="{' bg-white p-2 text-black font-semibold' : activeTab === 'myappointments'}">
                    My Appointments
                </button>
                <button @click="activeTab = 'myinformation'" type="button" class="h-8 px-2 rounded-lg flex justify-center items-center" :class="{' bg-white p-2 text-black font-semibold' : activeTab === 'myinformation'}">
                    My Information
                </button>
                <button @click="activeTab = 'newappointment'" type="button" class="h-8 px-2 rounded-lg flex justify-center items-center" :class="{' bg-white p-2 text-black font-semibold' : activeTab === 'newappointment'}">
                    New Appointment
                </button>
            </div>
            <button @click="bookAppointment = true" type="button" class="font-semibold px-4 rounded-lg bg-blue-500 text-white">Book Appointment</button>
        </div>
        <div v-if=" activeTab === 'myappointments'">
            <Myappointments/>
        </div>
        <div v-else-if="activeTab === 'myinformation'">
            <div class="flex flex-col h-full">
                <form class="mt-5 grid grid-cols-2 gap-2" @submit="handleSaveData">
                    <div class="flex flex-col">
                        <label class="font-semibold" for="firstname">First Name</label>
                        <input v-model="userInformation.firstname" class="border-2 rounded-md px-4 py-2 h-10" placeholder="First Name" type="text" name="firstname" id="firstname" />
                    </div>
                    <div class="flex flex-col">
                        <label class="font-semibold" for="lastname">Last Name</label>
                        <input v-model="userInformation.lastname" class="border-2 rounded-md px-4 py-2 h-10" placeholder="Last Name" type="text" name="lastname" id="lastname"/>
                    </div>
                    <div class="flex flex-col">
                        <label class="font-semibold" for="email">Email</label>
                        <input v-model="userInformation.email" class="border-2 rounded-md px-4 py-2 h-10" placeholder="Email" type="email" name="email" id="email" />
                    </div>
                    <div class="flex flex-col">
                        <label class="font-semibold" for="phonenumber">Phone</label>
                        <input v-model="userInformation.phone" ref="phoneNumberInput" class="border-2 rounded-md px-4 py-2 h-10" type="tel" name="phonenumber" id="phonenumber">
                        <p class="text-red-400">{{ errorPhoneMsg }}</p>
                    </div>
                    <div class=" self-start">
                        <button class="border-2 px-3" type="submit">Save</button>
                    </div>
                </form>
                <form class="mt-5 grid grid-cols-2 gap-2" @submit="handleChangePassword">
                    <div class="flex flex-col">
                        <label class="font-semibold" for="password">Password</label>
                        <input v-model="changepassword.password" class="border-2 rounded-md px-4 py-2 h-10" type="password" name="password" id="password" />
                    </div>
                    <div class="flex flex-col">
                        <label class="font-semibold" for="cnfpassword">Confirm Password</label>
                        <input v-model="changepassword.cnfpassword" class="border-2 rounded-md px-4 py-2 h-10" type="password" name="cnfpassword" id="cnfpassword"/>
                        <p class="text-red-400">{{ errorPasswordMsg }}</p>
                    </div>
                    <button type="submit">Change Password</button>
                </form>
            </div>
        </div>
        <div v-else-if="activeTab === 'newappointment'">
            <div id="boss_booking_root_inline" class="mt-3">
            </div>
        </div>
    </div>
</template>