<script setup lang="ts">

const props = defineProps<{
  isOpen: boolean,
  title:string
}>();

const emit = defineEmits(['update:isOpen']);

const closePopup = () => {
  emit('update:isOpen', false);
};
</script>

<template>
  <div
    class="bg-slate-300/50 min-h-screen min-w-[100vw] z-10 top-0 left-0 fixed flex items-center justify-center"
    v-if="props.isOpen"
    @click.self="closePopup"
  >
    <div class="relative flex flex-col w-full max-h-screen bg-white max-w-lg border bg-background pr-2 p-6 shadow-lg duration-200 overflow-x-auto"
    >
      <div class="flex justify-between items-center h-5 border-b-2 pb-2">
        <h1 class="font-bold text-base">{{ title }}</h1>
        <button
        type="button"
        class="absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
        @click="closePopup"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4">
          <path d="M18 6 6 18"></path>
          <path d="m6 6 12 12"></path>
        </svg>
        <span class="sr-only">Close</span>
      </button>
      </div>
      <div class="max-h-[90vh] overflow-x-auto">
        <slot name="body"></slot>
      </div>
      <div class="border-t-2 p-2 h-5">
        <slot name="action"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Add custom styles here if needed */
</style>
