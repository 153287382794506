import { createApp } from 'vue'
import './normalize.scss'
import './style.scss'
import Dashboard from './Dashboard.vue'
import { createPinia } from 'pinia'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'


// import ServiceCategoryComponent from './components/ServiceCategoryComponent.vue';
// import ServiceComponent from './components/ServiceComponent.vue';
// import AgentComponent from './components/AgentComponent.vue';
// import LocationComponent from './components/LocationComponent.vue';
// import DateTimeComponent from './components/DateTimeComponent.vue';
// import LoginComponent from './components/LoginComponent.vue';
// import PaymentModeComponent from './components/PaymentModeComponent.vue';
// import PaymentComponent from './components/PaymentComponent.vue';
// import ConfirmationComponent from './components/ConfirmationComponent.vue';


// const allSteps = [
//     {
//         "name": "ServiceCategoryComponent",
//         "element": ServiceCategoryComponent,
//     },
//     {
//         "name": "DateTimeComponent",
//         "element": DateTimeComponent,
//     },
//     {        
//         "name": 'LoginComponent',
//         "element": LoginComponent,
//     },
//     {
//         "name": "ServiceComponent",
//         "element": ServiceComponent,
//     },
//     {
//         "name": "AgentComponent",
//         "element": AgentComponent,
//     },
//     {
//         "name": "LocationComponent",
//         "element": LocationComponent,
//     },
//     {
//         "name": "PaymentModeComponent",
//         "element": PaymentModeComponent,
//     },
//     {
//         "name": "PaymentComponent",
//         "element": PaymentComponent,
//     },
//     {
//         "name": "ConfirmationComponent",
//         "element": ConfirmationComponent,
//     },
// ];

const dashboard = createApp(Dashboard);

// allSteps.forEach(element => {
//     app.component(element.name, element.element);
// });

// app.component('ServiceCategoryComponent', ServiceCategoryComponent);

const pinia = createPinia()
if (document.getElementById('boss_booking_dashboard')) {
    dashboard.use(pinia).use(autoAnimatePlugin).mount('#boss_booking_dashboard')
}