<script setup lang="ts">
import { onMounted, ref } from 'vue';
import Popup from './Popup.vue'
import repository from '../api/repository';
import DateTimeComponent from './DateTimeComponent.vue';
import { useSettingStore } from '../stores/settingsstore';
import { useBookingStore } from '../stores/bookingstore';
import { ApiResponse,DashboardData } from '../types/common-types';
import StepTitle from './StepTitle.vue';
import { getParsedTime } from '../general-functions';
const appointmentsData = ref<any>({})
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const isOpen = ref(false)
const settingsStore = useSettingStore();
const bookingStore = useBookingStore();
const isViewDetailsOpen = ref(false)
const appointmentSummary = ref<any>({})
const currency = ref({symbolaftercurrency:"",symbolbeforecurrency:""})

onMounted(async () => {
    try {
        const res:ApiResponse<DashboardData> = await repository.getUserAppointments(timezone)
        if (res.data.status === 200) {
            appointmentsData.value = res.data.data
            settingsStore.setSettings(res.data.data.settings)
        }
    } catch (e) {
        console.log(e)
    }

    currency.value = settingsStore.getSettings('currencysettings')
})

const cancelAppointment = async (id:number) => {
	const confirmCancel = confirm("Do you really want to cancel appointment")
	if (confirmCancel) {
		const res = await repository.cancelAppointment({id})
		if (res.data.status === 200) {
			window.location.reload()
		}
	}
}

const showCalendar = (id: number) => {
    isOpen.value = true
    const appointment = appointmentsData.value.upcoming.filter((item: any) => +item.id === +id)[0]
    console.log(appointment)
    bookingStore.bookingData.appointmentid = +id 
    bookingStore.bookingData.serviceid = +appointment.serviceid
    bookingStore.bookingData.locationid = +appointment.locationid
    bookingStore.bookingData.agentid = +appointment.agentid
    bookingStore.bookingData.serviceid = +appointment.serviceid
    bookingStore.bookingData.bufferafter = +appointment.bufferafter
    bookingStore.bookingData.bufferbefore = +appointment.bufferbefore
    bookingStore.bookingData.custid = +appointment.userid
    bookingStore.bookingData.duration = +appointment.duration
    bookingStore.bookingData.startdatetimeutc = appointment.startdatetimeutc
    bookingStore.bookingData.enddatetimeutc = appointment.enddatetimeutc
}

const rescheduleAppointment = async () => {
    try {
        const res = await repository.submitConfirm(bookingStore.bookingData)
        if (res.data.status === 200) {
            isOpen.value = false
        }
    } catch (e) {
        console.log(e)
    }
}

const showAppointmentSummary = async (id: number) => {
    try {
        const res: ApiResponse<any> = await repository.getAppointmentSummary(id, bookingStore.bookingData.timezone)
        if (res.data.status === 200) {
            appointmentSummary.value = res.data.data
            isViewDetailsOpen.value = true
        }
    } catch (e) {
        console.log(e)
    }
}

const getDuration = (minutes:number) =>  {
    // Calculate hours and remaining minutes
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    // Construct the result string
    let result = "";
    if (hours > 0) {
        result += hours + (hours === 1 ? " Hour" : " Hours");
    }
    if (remainingMinutes > 0) {
        if (hours > 0) {
            result += " ";
        }
        result += remainingMinutes + (remainingMinutes === 1 ? " Minute" : " Minutes");
    }

    return result;
}

</script>

<template>
    <Popup v-model:is-open="isOpen" title="Select Date & Time">
        <template #body>
            <DateTimeComponent :hide-title="true"/>
        </template>
        <template #action>
        <div class="flex flex-col-reverse sm:flex-row">
            <button
              class="inline-flex items-center justify-stretch rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
              type="button"
              @click="isOpen = false"
            >
              Cancel
            </button>
            <button
              class="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
              type="button"
              @click="rescheduleAppointment"
            >
              Reschedule
            </button>
        </div>
        </template>
    </Popup>

    <Popup v-model:is-open="isViewDetailsOpen" title="Booking Details">
        <template #body>
            <div class="mt-4">
              <div>
                <div class="flex flex-col justify-between items-center py-2">
                  <div class="text-lg">Confirmation #<strong>{{ appointmentSummary.appointmentid }}</strong></div>
                  <div class="flex space-x-4">
                      <button type="button" class="flex items-center text-blue-500 hover:text-blue-700">
                        Add to Calendar
                      </button>
                      <button type="button" class="flex items-center text-blue-500 hover:text-blue-700">
                        Print
                      </button>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <div class="flex flex-col text-sm">
                    <StepTitle :title="appointmentSummary.servicename" class="pl-0"/>
                    <div class="text-lg font-semibold">{{getDuration(appointmentSummary.duration)}}</div>
                    <div class="text-gray-500">{{ appointmentSummary.startdate }}, {{ getParsedTime(+appointmentSummary.starttime,'12') }}</div>
                </div>
                <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div class="flex flex-col">
                    <StepTitle title="User" class="pl-0 pb-1"/>
                    <div class="border border-gray-200 rounded-md p-4 pt-1">
                        <div class="flex items-center space-x-4">
                          <div class="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">{{ appointmentSummary.username[0] }}</div>
                          <div>
                            <h1 class="font-semibold">{{appointmentSummary.username}}</h1>
                            <p class="text-gray-500 text-[10px]">{{ appointmentSummary.email }}</p>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <StepTitle title="Agent" class="pl-0 pb-1"/>
                    <div class="border border-gray-200 rounded-md p-4 pt-1">
                       <div class="flex items-center space-x-4">
                          <div class="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">{{ appointmentSummary.agentname[0] }}</div>
                          <div>
                            <h1 class="font-semibold">{{appointmentSummary.agentname}}</h1>
                            <p class="text-gray-500 text-[10px]">{{ appointmentSummary.agentemail }}</p>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                <StepTitle title="Location" class="pl-0 pb-0"/>
                <div class="mt-4 border border-gray-200 rounded-md p-4 pt-1">
                    <div class="flex items-center space-x-4">
                    <div class="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">{{ appointmentSummary.locationname[0] }}</div>
                      <div>
                        <h1 class="font-semibold">{{appointmentSummary.locationname}}</h1>
                        <p class="text-gray-500 text-[10px]">{{ appointmentSummary.address }}</p>
                      </div>
                    </div>
                </div>
                <div class="mt-4">
                  <div class="font-semibold text-lg">Cost Breakdown</div>
                  <div class="mt-2">
                    <div class="flex justify-between py-2">
                      <div>Payment Method: <strong>{{appointmentSummary.paymentmode}}</strong></div>
                    </div>
                    <div class="border-t border-gray-200 py-2">
                      <div class="flex justify-between">
                        <div>Service</div>
                        <div>{{ `${currency.symbolbeforecurrency} ${appointmentSummary.price} ${currency.symbolaftercurrency}` }}</div>
                      </div>
                    </div>
                    <div class="flex justify-between font-semibold py-2">
                      <div>Total Price</div>
                      <div>{{ `${currency.symbolbeforecurrency} ${appointmentSummary.price} ${currency.symbolaftercurrency}` }}</div>
                    </div>
                    <div class="flex justify-between py-2">
                      <div>Payments and Credits</div>
                      <div>$0.00</div>
                    </div>
                    <div class="flex justify-between font-semibold py-2">
                      <div>Balance Due</div>
                      <div>{{ `${currency.symbolbeforecurrency} ${appointmentSummary.price} ${currency.symbolaftercurrency}` }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </template>
        <template #action>
            <button type="button" @click="isViewDetailsOpen = false">Close</button>
        </template>
    </Popup>
    <div class="flex flex-col">
        <div v-if="appointmentsData.upcoming" >
            <h1 class="border-b-2 pb-2 mt-3 font-semibold">Upcoming</h1>
            <div class="grid grid-cols-4 gap-2">
                <div
            v-for="appointment in appointmentsData.upcoming"
            :key="appointment.id"
            class="border-2 rounded-xl p-3 flex-1 m-1 px-3 gap-2"
            >
            <h1 class="font-bold">{{ appointment.servicename }}</h1>
            <p class="text-[12px] text-slate-400">
                {{ appointment.startdate }}, {{ appointment.starttime }} - {{ appointment.endtime }}
            </p>

            <button
                v-if="appointment.iscustomercanreschedule"
                type="button"
                @click="showCalendar(appointment.id)"
                class="px-2 py-1 border-2 rounded text-blue-500 border-blue-500 text-[10px] mr-3"
            >
                Reschedule
            </button>

            <button
                v-if="appointment.iscustomercancancel"
                type="button"
                @click="cancelAppointment(appointment.id)"
                class="px-2 py-1 border-2 rounded text-red-500 border-red-500 text-[10px]"
            >
                Cancel
            </button>

            <div class="border-b-[1px] my-2"></div>

            <div class="flex justify-between w-1/2 text-sm items-center">
                <span>Agent</span>
                <span>{{ appointment.agentname }}</span>
            </div>

            <div class="flex justify-between w-1/2 text-sm items-center">
                <span>Status</span>
                <span
                :class="{
                    'bg-green-500 text-white font-bold p-1': appointment.status === 'confirmed',
                    'bg-amber-400 text-white font-bold p-1': appointment.status !== 'confirmed'
                }"
                >
                {{ appointment.status }}
                </span>
            </div>

            <div class="border-b-[1px] my-2"></div>

            <button @click="showAppointmentSummary(+appointment.id)" type="button" class="px-3 py-2 border-2">
                View Details
            </button>
            </div>
            </div>
            
        </div>
        <div v-if="appointmentsData.older">
            <h1 class="border-b-2 pb-2 mt-3 font-semibold">Older</h1>
            <div class="grid grid-cols-4 gap-2">
                <div
            v-for="appointment in appointmentsData.older"
            :key="appointment.id"
            class="border-2 rounded-xl p-3 flex-1 m-1 px-3 gap-2"
            >
            <h1 class="font-bold">{{ appointment.servicename }}</h1>
            <p class="text-[12px] text-slate-400">
                {{ appointment.startdate }}, {{ appointment.starttime }} - {{ appointment.endtime }}
            </p>

            <div class="border-b-[1px] my-2"></div>

            <div class="flex justify-between w-1/2 text-sm items-center">
                <span>Agent</span>
                <span>{{ appointment.agentname }}</span>
            </div>

            <div class="flex justify-between w-1/2 text-sm items-center">
                <span>Status</span>
                <span
                :class="{
                    'bg-green-500 text-white font-bold p-1': appointment.status === 'confirmed',
                    'bg-amber-400 text-white font-bold p-1': appointment.status !== 'confirmed'
                }"
                >
                {{ appointment.status }}
                </span>
            </div>

            <div class="border-b-[1px] my-2"></div>

            <button @click="showAppointmentSummary(+appointment.id)" type="button" class="px-3 py-2 border-2">
                View Details
            </button>
            </div>
            </div>
        </div>
        <div v-if="appointmentsData.cancelled">
            <h1 class="border-b-2 pb-2 mt-3 font-semibold">Cancelled</h1>
            <div class="grid grid-cols-4 gap-2">
                <div
            v-for="appointment in appointmentsData.cancelled"
            :key="appointment.id"
            class="border-2 rounded-xl p-3 flex-1 m-1 px-3 gap-2"
            >
            <h1 class="font-bold">{{ appointment.servicename }}</h1>
            <p class="text-[12px] text-slate-400">
                {{ appointment.startdate }}, {{ appointment.starttime }} - {{ appointment.endtime }}
            </p>

            <div class="border-b-[1px] my-2"></div>

            <div class="flex justify-between w-1/2 text-sm items-center">
                <span>Agent</span>
                <span>{{ appointment.agentname }}</span>
            </div>

            <div class="flex justify-between w-1/2 text-sm items-center">
                <span>Status</span>
                <span
                class="bg-red-500 text-white font-bold p<any></any>-1'"
                >
                {{ appointment.status }}
                </span>
            </div>

            <div class="border-b-[1px] my-2"></div>

            <button @click="showAppointmentSummary(+appointment.id)" type="button" class="px-3 py-2 border-2">
                View Details
            </button>
            </div>
            </div>
        </div>
  </div>
</template>