import { createApp } from 'vue'
import './normalize.scss'
import './style.scss'
import App from './App.vue'
import { createPinia } from 'pinia'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

import ServiceCategoryComponent from './components/ServiceCategoryComponent.vue';
import ServiceComponent from './components/ServiceComponent.vue';
import AgentComponent from './components/AgentComponent.vue';
import LocationComponent from './components/LocationComponent.vue';
import DateTimeComponent from './components/DateTimeComponent.vue';
import LoginComponent from './components/LoginComponent.vue';
import PaymentModeComponent from './components/PaymentModeComponent.vue';
import PaymentComponent from './components/PaymentComponent.vue';
import ConfirmationComponent from './components/ConfirmationComponent.vue';
// import ApiPlugin from './plugins/apiPlugin';
// import AxiosPlugin from './plugins/axiosPlugin';
import { useAxios } from './plugins/useAxios';
import 'intl-tel-input/build/css/intlTelInput.css';



const allSteps = [
    {
        "name": "ServiceCategoryComponent",
        "element": ServiceCategoryComponent,
    },
    {
        "name": "DateTimeComponent",
        "element": DateTimeComponent,
    },
    {        
        "name": 'LoginComponent',
        "element": LoginComponent,
    },
    {
        "name": "ServiceComponent",
        "element": ServiceComponent,
    },
    {
        "name": "AgentComponent",
        "element": AgentComponent,
    },
    {
        "name": "LocationComponent",
        "element": LocationComponent,
    },
    {
        "name": "PaymentModeComponent",
        "element": PaymentModeComponent,
    },
    {
        "name": "PaymentComponent",
        "element": PaymentComponent,
    },
    {
        "name": "ConfirmationComponent",
        "element": ConfirmationComponent,
    },
];

const app = createApp(App);

allSteps.forEach(element => {
    app.component(element.name, element.element);
});

// app.component('ServiceCategoryComponent', ServiceCategoryComponent);

const pinia = createPinia()

useAxios();

app.use(pinia).use(autoAnimatePlugin)

const root:any = document.getElementById('boss_booking_root')

if (root && root.__vue_app__ === undefined) {
    app.mount('#boss_booking_root');
}


export function createBookingApp() {
  const appInstance = createApp(App);
  allSteps.forEach(element => {
    appInstance.component(element.name, element.element);
  });
  const pinia = createPinia();
  appInstance.use(pinia).use(autoAnimatePlugin);
  return appInstance;
}
